import store from "@/store";
export default [
 
  
  {
    title: 'Organizations',
    route: 'customers',
    icon: 'UsersIcon',
    permission:store.state.auth.permissions.includes('customer-list'),
  },

  {
    header:'Users',
    permission:true,
  },
  {
    title: 'Secusy+ Users',
    route: 'consultants',
    icon: 'UserIcon',
    permission:store.state.auth.permissions.includes('consultant-list'),
  },
  {
    title: 'Customer Users',
    route: 'customerusers',
    icon: 'UserIcon',
    permission:store.state.auth.permissions.includes('consultant-list'),
  },
  {
    title: 'Deleted Users',
    route: 'deleted-users',
    icon: 'UserIcon',
    permission:store.state.auth.permissions.includes('consultant-list'),
  },

  {
    header:'Settings',
    permission:true,
  },
  {
    title: 'Secusy Admins',
    route: 'usermanagement',
    icon: 'SettingsIcon',
    permission:store.state.auth.permissions.includes('user-list'),
  },
  {
    title: 'Roles',
    route: 'roles',
    icon: 'UserCheckIcon',
    permission:store.state.auth.permissions.includes('role-list'),
  },
  {
    title: 'Features',
    route: 'features',
    icon: 'StarIcon',
    permission: true,
  },
  {
    title: 'Plans',
    route: 'plans',
    icon: 'MapIcon',
    permission: true,
  },
]
